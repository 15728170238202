import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const NavBar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 46px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 25px;
  border: none;
  background-color: #008000;
  font-weight: bold;
  color: #fff;
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  transition: .2s;
  :hover{
    transform: translate3d(0, 3px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 915px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const mintAmount = 1;
  const [totalSupply_0, settotalSupply0] = useState(0);
  const [totalSupply_1, settotalSupply1] = useState(0);
  const [totalSupply_2, settotalSupply2] = useState(0);
  const [totalSupply_3, settotalSupply3] = useState(0);
  const [totalSupply_4, settotalSupply4] = useState(0);
  const [mintLimit_0, setMintLimit0] = useState(0);
  const [mintLimit_1, setMintLimit1] = useState(0);
  const [mintLimit_2, setMintLimit2] = useState(0);
  const [mintLimit_3, setMintLimit3] = useState(0);
  const [mintLimit_4, setMintLimit4] = useState(0);
  const [minted_0, setaddressMinted0] = useState(0);
  const [minted_1, setaddressMinted1] = useState(0);
  const [minted_2, setaddressMinted2] = useState(0);
  const [minted_3, setaddressMinted3] = useState(0);
  const [minted_4, setaddressMinted4] = useState(0);
  const [claimingNft_0, setClaimingNft0] = useState(false);
  const [claimingNft_1, setClaimingNft1] = useState(false);
  const [claimingNft_2, setClaimingNft2] = useState(false);
  const [claimingNft_3, setClaimingNft3] = useState(false);
  const [claimingNft_4, setClaimingNft4] = useState(false);
  const [feedback_0, setFeedback0] = useState(``);
  const [feedback_1, setFeedback1] = useState(``);
  const [feedback_2, setFeedback2] = useState(``);
  const [feedback_3, setFeedback3] = useState(``);
  const [feedback_4, setFeedback4] = useState(``);
  const [sale_0, setPublic0] = useState("");
  const [sale_1, setPublic1] = useState("");
  const [sale_2, setPublic2] = useState("");
  const [sale_3, setPublic3] = useState("");
  const [sale_4, setPublic4] = useState("");
  //AL check 削除
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY_0: 1,
    MAX_SUPPLY_1: 1,
    MAX_SUPPLY_2: 1,
    MAX_SUPPLY_3: 1,
    MAX_SUPPLY_4: 1,
    WEI_COST_0: 0,
    WEI_COST_1: 0,
    WEI_COST_2: 0,
    WEI_COST_3: 0,
    WEI_COST_4: 0,
    DISPLAY_COST_0: 0,
    DISPLAY_COST_1: 0,
    DISPLAY_COST_2: 0,
    DISPLAY_COST_3: 0,
    DISPLAY_COST_4: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  //public start check
  const checkPublic0 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(0)
      .call()
      .then((receipt) => {
        setPublic0(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublic1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(1)
      .call()
      .then((receipt) => {
        setPublic1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublic2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(2)
      .call()
      .then((receipt) => {
        setPublic2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublic3 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(3)
      .call()
      .then((receipt) => {
        setPublic3(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublic4 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(4)
      .call()
      .then((receipt) => {
        setPublic4(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  //supply check
  const checkTotalSupply0 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(0)
      .call()
      .then((receipt) => {
        settotalSupply0(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(1)
      .call()
      .then((receipt) => {
        settotalSupply1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(2)
      .call()
      .then((receipt) => {
        settotalSupply2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply3 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(3)
      .call()
      .then((receipt) => {
        settotalSupply3(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply4 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(4)
      .call()
      .then((receipt) => {
        settotalSupply4(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  //limit check
  const checkMintLimit0 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(0)
      .call()
      .then((receipt) => {
        setMintLimit0(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(1)
      .call()
      .then((receipt) => {
        setMintLimit1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(2)
      .call()
      .then((receipt) => {
        setMintLimit2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit3 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(3)
      .call()
      .then((receipt) => {
        setMintLimit3(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit4 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(4)
      .call()
      .then((receipt) => {
        setMintLimit4(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  //minted check
  const checkaddressMinted0 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(0, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMinted0(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMinted1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(1, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMinted1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMinted2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(2, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMinted2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMinted3 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(3, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMinted3(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMinted4 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(4, blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMinted4(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  //AL check 削除

  //mint
  const claimNFT0 = () => {
    let cost = CONFIG.WEI_COST_0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback0(`ミント中です...`);
    setClaimingNft0(true);
    blockchain.smartContract.methods
      .publicMint(0, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback0("失敗したので再度試してみてください！");
        setClaimingNft0(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback0(
          `ミントが成功しました！`
        );
        setClaimingNft0(false);
        checkaddressMinted0();
        checkTotalSupply0();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT1 = () => {
    let cost = CONFIG.WEI_COST_1;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback1(`ミント中です...`);
    setClaimingNft1(true);
    blockchain.smartContract.methods
      .publicMint(1, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback1("失敗したので再度試してみてください！");
        setClaimingNft1(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback1(
          `ミントが成功しました！`
        );
        setClaimingNft1(false);
        checkaddressMinted1();
        checkTotalSupply1();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT2 = () => {
    let cost = CONFIG.WEI_COST_2;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback2(`ミント中です...`);
    setClaimingNft2(true);
    blockchain.smartContract.methods
      .publicMint(2, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback2("失敗したので再度試してみてください！");
        setClaimingNft2(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback2(
          `ミントが成功しました！`
        );
        setClaimingNft2(false);
        checkaddressMinted2();
        checkTotalSupply2();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT3 = () => {
    let cost = CONFIG.WEI_COST_3;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback3(`ミント中です...`);
    setClaimingNft3(true);
    blockchain.smartContract.methods
      .publicMint(3, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback3("失敗したので再度試してみてください！");
        setClaimingNft3(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback3(
          `ミントが成功しました！`
        );
        setClaimingNft3(false);
        checkaddressMinted3();
        checkTotalSupply3();
        dispatch(fetchData(blockchain.account));
      });
  };


  //BigInt使用
  const claimNFT4 = () => {
    let cost = BigInt(CONFIG.WEI_COST_4);
    let gasLimit = BigInt(CONFIG.GAS_LIMIT);
    let mintAmount = BigInt(1);//BigIntに揃える
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback4(`ミント中です...`);
    setClaimingNft4(true);
    blockchain.smartContract.methods
      .publicMint(4, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback4("失敗したので再度試してみてください！");
        setClaimingNft4(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback4(
          `ミントが成功しました！`
        );
        setClaimingNft4(false);
        checkaddressMinted4();
        checkTotalSupply4();
        dispatch(fetchData(blockchain.account));
      });
  };



  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply0();
    checkTotalSupply1();
    checkTotalSupply2();
    checkTotalSupply3();
    checkTotalSupply4();
    checkPublic0();
    checkPublic1();
    checkPublic2();
    checkPublic3();
    checkPublic4();
    checkMintLimit0();
    checkMintLimit1();
    checkMintLimit2();
    checkMintLimit3();
    checkMintLimit4();
    checkaddressMinted0();
    checkaddressMinted1();
    checkaddressMinted2();
    checkaddressMinted3();
    checkaddressMinted4();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply0();
    checkTotalSupply1();
    checkTotalSupply2();
    checkTotalSupply3();
    checkTotalSupply4();
    checkPublic0();
    checkPublic1();
    checkPublic2();
    checkPublic3();
    checkPublic4();
    checkMintLimit0();
    checkMintLimit1();
    checkMintLimit2();
    checkMintLimit3();
    checkMintLimit4();
    checkaddressMinted0();
    checkaddressMinted1();
    checkaddressMinted2();
    checkaddressMinted3();
    checkaddressMinted4();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <NavBar>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img style={{maxHeight: "32px", margin: "8px 0px 0px 12px"}}  src="/config/images/logo.png" alt="logo" />
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "12px" }}>
            <a href="https://opensea.io/collection/dreaminmall" target="_blank" rel="noopener" style={{ marginRight: "12px" }}>
              <img style={{ width: "32px",marginTop:"7px" }} src="/config/images/icon_opensea.png" alt="OpenSea Icon"></img>
            </a>
            <a href="https://twitter.com/DreaminDiversPJ" target="_blank" rel="noopener">
              <img style={{ width: "32px",margin:"7px 0px 0px 0px" }} src="/config/images/icon_twitter.png" alt="Twitter Icon"></img>
            </a>
          </div>
        </div>
      </NavBar>

      <s.TextDescription>
        <img style={{maxWidth: "100%", textAlign: "center", padding: "40px 0px 0px 0px"}}  src="/config/images/top.png" alt="top" />
      </s.TextDescription>

      <s.TextDescription
        style={{margin:"10px 0px 15px 0px",textAlign:"center",fontSize: 24,color: "#fff"}}
      >
        商品一覧
      </s.TextDescription>
      
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '80%', textAlign: "center"}}>
        <s.Container
          style={{
            backgroundColor: "#fff",
            borderRadius: 20,
            padding: 20,
            border: "none",
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            textAlign:"center",
            color:"#4d4d4d"
          }}
        >
          <s.SpacerSmall/>
          <s.Container ai={"center"} jc={"center"} style={{fontWeight:"bold", fontSize:"28px"}}>
            DDPオリジナル米
          </s.Container>
          <s.Container style={{ display: 'flex', flexDirection: 'row' }}>
            <img style={{margin:"20px 0px", maxWidth: "40%"}} src="/config/images/1.png"/>
            <div style={{padding:"25px 15px 40px 25px"}}>説明説明説明説明説明説明説明説明説明説明説明説明説明</div>
          </s.Container>
          <s.Container ai={"center"} jc={"center"}>
            0.01ETH (Polygon)
          </s.Container>
            <s.Container ai={"center"} jc={"center"}>
              <s.SpacerSmall />
              <a href="https://opensea.io/assets/matic/0x904d76c7364ad749c647a870cffc3427a73f9dfc/1" target="_blank" rel="noopener">
              <StyledButton>
                  販売所(OpenSea)へ
              </StyledButton>
              </a>
            </s.Container>
            <s.SpacerSmall />
          </s.Container>
        </div>
      </div>


        <div style={{ marginTop:"20px",display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '80%', textAlign: "center"}}>
        <s.Container
          style={{
            backgroundColor: "#fff",
            borderRadius: 20,
            padding: 20,
            border: "none",
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            textAlign:"center",
            color:"#4d4d4d"
          }}
        >
          <s.SpacerSmall/>
          <s.Container ai={"center"} jc={"center"} style={{fontWeight:"bold", fontSize:"28px"}}>
          Gyoiiiiiin!!冷凍餃子
          </s.Container>
          <s.Container style={{ display: 'flex', flexDirection: 'row' }}>
            <img style={{margin:"20px 0px", maxWidth: "40%"}} src="/config/images/2.png"/>
            <div style={{padding:"25px 15px 40px 25px"}}>説明説明説明説明説明説明説明説明説明説明説明説明説明</div>
          </s.Container>
          <s.Container ai={"center"} jc={"center"}>
            0.02ETH (Polygon)
          </s.Container>
                <s.Container ai={"center"} jc={"center"}>
                <s.SpacerSmall />
                <a href="https://opensea.io/assets/matic/0x904d76c7364ad749c647a870cffc3427a73f9dfc/2" target="_blank" rel="noopener">
                <StyledButton>
                  販売所(OpenSea)へ
              </StyledButton>
              </a>
                </s.Container>
                <s.SpacerSmall></s.SpacerSmall>
            </s.Container>
            </div>
          </div>

          <div style={{ marginTop:"20px",display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '80%', textAlign: "center"}}>
        <s.Container
          style={{
            backgroundColor: "#fff",
            borderRadius: 20,
            padding: 20,
            border: "none",
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            textAlign:"center",
            color:"#4d4d4d"
          }}
        >
          <s.SpacerSmall/>
          <s.Container ai={"center"} jc={"center"} style={{fontWeight:"bold", fontSize:"28px"}}>
          沖縄石鹸
          </s.Container>
          <s.Container style={{ display: 'flex', flexDirection: 'row' }}>
            <img style={{margin:"20px 0px", maxWidth: "40%"}} src="/config/images/1.png"/>
            <div style={{padding:"25px 15px 40px 25px"}}>説明説明説明説明説明説明説明説明説明説明説明説明説明</div>
          </s.Container>
          <s.Container ai={"center"} jc={"center"}>
            0.02ETH (Polygon)
          </s.Container>
            
          {Number(totalSupply_0) >= CONFIG.MAX_SUPPLY_0 ? (
            <>
            <s.SpacerSmall/>
            <s.Container ai={"center"} jc={"center"} style={{fontWeight:"bold", fontSize:"20px"}}>
              完売御礼！
            </s.Container>
              <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                →{CONFIG.MARKETPLACE}
              </StyledLink>
            </>
          ) : (
            <>
              {blockchain.account === "" || blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT
                </StyledButton>                      
              {blockchain.errorMsg !== "" ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                    }}
                  >
                  {blockchain.errorMsg}
                  </s.TextDescription>
                </>
              ) : null}
                </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                        textAlign: "center",
                        color: "#4d4d4d",
                        }}
                      >
                        {feedback_0}
                      </s.TextDescription>
                      {/* セール0ここから */}
                      {sale_0 == true ? (//セールスタート確認
                        <>
                        {minted_0 > mintLimit_0 - 1 ? (
                          <>
                          <s.SpacerMedium />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={1}//claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              style={{fontFamily: "'Italiana'", fontFamily: "DM+Serif+Display" }}
                            >
                              {"購入済み"}
                            </StyledButton>
                            </s.Container>
                            </>
                          ) : (
                            <>
                            <s.SpacerSmall />
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledButton
                                  disabled={claimingNft_0 ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                  onClick={(e) => {
                                    e.preventDefault();
                                    claimNFT0();
                                    getData();
                                  }}
                                >
                                {claimingNft_0 ? "MINTING" : "MINT"}
                                </StyledButton>
                              </s.Container>
                              {minted_2 > 0 ? (
                                  <>
                                  </>                              
                              ):(
                              <></>
                              )}
                            </>
                          )}
                          </>
                        ) : (
                          <>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <s.TextDescription
                            style={{
                              color: "#4d4d4d", fontFamily: "DM+Serif+Display"
                            }}
                          >
                          {"セール開始までお待ちください"}
                          </s.TextDescription>
                          </s.Container>
                          </>
                        )}
                        {/* セール0ここまで */}

                    </>
                  )}
                </>
              )}
              <s.SpacerSmall/>
            </s.Container>
            </div>
          </div>
          
      <s.SpacerLarge />
    </s.Screen>
  );
}
export default App;